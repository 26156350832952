div#fullvision div#first {
    margin-top: auto;
    margin-bottom: auto;
}

div#fullTitle {
    font-size: 8vh;
    padding-bottom: 3vh;
}

div#fullvision div#first :first-child {
    font-size: 3.5vh;
}

div#fullvision div#first :nth-child(2) {
    font-size: 2vh;
}

div#fullvision div#bloque2Full {
    padding-top: 8vh;
}

div#firstFull {
    font-size: 2.5vh;
    font-weight: 500;
    padding-bottom: 2vh;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 400px;
    /* Adecua este valor para controlar la altura del video */
    position: relative;
    height: 0;
}

video {
    width: 100%;
    height: auto;
}

.video-wrapperFl {
    /* Telling our absolute positioned video to 
    be relative to this element */


    width: 500px;
    height: 650px;
    left: 0;
    /* Will not allow the video to overflow the 
    container */
    overflow: hidden;
    /* Centering the container's content vertically 
    and horizontally */
    text-align: center;
    display: block;
    align-items: center;
    justify-content: center;

}