div#work {
    font-size: 4vh;
    font-weight: 500;
    color: #1A1A51;
}

img#imgWork {
    width: 100%;
    height: auto;
    border: 2px solid #141a51;
}

img#division {
    height: auto;
    width: 100%;
}

div#chance {
    font-size: 4vh;
    font-weight: 500;
    color: #1A1A51;
    padding-top: 5vh;
}

div#bloque1Work {
    padding-top: 5vh;
}

div#bloque2Work {
    padding-top: 5vh;
}

div#bloque3Work {
    padding-top: 15vh;
}

div#bloque3Form {
    padding-top: 5vh;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

div#bloque3Work div#titleWork {
    font-size: 3vh;
    font-weight: 500;
}

div#textWork {
    margin-top: auto;
    margin-bottom: auto;
}

div#textWork div#firstWork {
    font-size: 2.8vh;
    font-weight: 500;
}

div#textWork div#secondWork {
    padding-top: 2vh;
    font-size: 2vh;
    font-weight: 400;
}

div#textWork div#thirdWork {
    padding-top: 2vh;
    font-size: 1.9vh;
    color: #A5A4A4;
}

div#bloq1 {
    margin-top: auto;
    margin-bottom: auto;
}

div#bloq1 :first-child {
    font-size: 4.5vh;
    padding-bottom: 4.5vh;
}

div#bloq1 :nth-child(2) {
    font-size: 2.8vh;
    padding-bottom: 4.5vh;
}

div#bloque3Work div#tableWork {
    width: 1080px;
}

table#work {
    width: 100%;
    display: table;
    border: 1px solid #A5A4A4;
    border-radius: 100px;
}

table#tableFile {
    border: 1px dashed #A5A4A4;
    border-radius: 25px;
    width: 100%;
    text-align: center;
}

table#tableFile tr {
    height: 150px;
}

table#tableFile Button {
    color: #A5A4A4;
    text-transform: none !important;
}

table#workCell {
    display: none;
}

div#sendTr {
    padding-top: 5vh;
}

button#sendTr {
    font-size: 3vh;
    float: right;
    border-radius: 60vh;
    text-transform: none !important;
}

img#imgSend {
    width: 50%;
    height: auto;
}

img#vect {
    display: block;
}


/*
@media only screen and (max-height: 936px) {
    /*img#imgWork {
        width: 630px;
    }
}
*/

@media screen and (width >=280px) and (width <=480px) {
    img#vect {
        display: none;
    }

    div#chance {
        font-size: 3.5vh;
        font-weight: 500;
    }

    table#work {
        display: none;
    }

    table#workCell {
        width: 100%;
        display: table;
        border-spacing: 0 15px;
    }

    table#workCell td {
        border: 1px solid #A5A4A4;
        border-radius: 100px;
    }

    #textFormCellW {
        color: #A5A4A4;
        font-weight: 600;
        padding-left: 5%;
    }
}

@media screen and (max-width:767px) and (width <=1200px) {
    img#vect {
        display: none;
    }

    div#chance {
        font-size: 3.5vh;
        font-weight: 500;
    }

    table#work {
        display: none;
    }

    table#workCell {
        width: 100%;
        display: table;
        border-spacing: 0 15px;
    }

    table#workCell td {
        border: 1px solid #A5A4A4;
        border-radius: 100px;
    }

    #textFormCellW {
        color: #A5A4A4;
        font-weight: 600;
        padding-left: 5%;
    }


    div#textWork div#firstWork {
        font-size: 23px;
        font-weight: 500;
    }

    div#textWork div#secondWork {
        padding-top: 2vh;
        font-size: 15px;
        font-weight: 400;
    }

    div#textWork div#thirdWork {
        padding-top: 2vh;
        font-size: 13px;
        color: #858585;
    }
}

@media screen and (min-width:768px) and (width <=1200px) {
    img#vect {
        display: none;
    }

    div#chance {
        font-size: 3.5vh;
        font-weight: 500;
    }

    table#work {
        display: none;
    }

    table#workCell {
        width: 100%;
        display: table;
        border-spacing: 0 15px;
    }

    table#workCell td {
        border: 1px solid #A5A4A4;
        border-radius: 100px;
    }

    #textFormCellW {
        color: #A5A4A4;
        font-weight: 600;
        padding-left: 5%;
    }


    div#textWork div#firstWork {
        font-size: 23px;
        font-weight: 500;
    }

    div#textWork div#secondWork {
        padding-top: 2vh;
        font-size: 15px;
        font-weight: 400;
    }

    div#textWork div#thirdWork {
        padding-top: 2vh;
        font-size: 13px;
        color: #858585;
    }
}