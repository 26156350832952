/*Styles imgs*/
div#index div img#abogada {
    display: block;
    height: auto;
    width: 100%;
}

img#trabajador {
    height: auto;
    width: 100%;
}

img#fullvision {
    height: auto;
    width: 100%;
}

img#peru {
    height: auto;
    width: 100%;
}

div#text2Bloq4IndexCell-2 {
    display: none;
}

/*End styles imgs*/

div#text1 {
    margin-top: 2.5vh;
    font-size: 3.5vh;
    font-weight: 500;
}

div#bloque2 {
    color: #1A1A51;
}

div#bloque2 :first-child :first-child {
    margin-top: 2.5vh;
    font-size: 6.5vh;
    font-weight: 500;
}

div#bloque2 :first-child :nth-child(2) {
    margin-top: 2vh;
    font-size: 3vh;
    font-weight: 500;
}

div#bloque2 :nth-child(2) :first-child {
    margin-top: 2.5vh;
    font-size: 6.5vh;
    font-weight: 500;
}

div#bloque2 :nth-child(2) :nth-child(2) {
    margin-top: 2vh;
    font-size: 3vh;
    font-weight: 500;
}

div#bloque2 :nth-child(3) :first-child {
    margin-top: 2.5vh;
    font-size: 6.5vh;
    font-weight: 500;
}

div#bloque2 :nth-child(3) :nth-child(2) {
    margin-top: 2vh;
    font-size: 3vh;
    font-weight: 500;
}

div#bloque3 {
    padding-top: 6vh;
}

div#bloque3 :first-child {
    margin-top: auto;
    margin-bottom: auto;
}

div#bloque3 :first-child #first {
    font-size: 4vh;
    color: #1A1A51;
}


div#bloque3 :first-child #second {
    margin-top: 2vh;
    font-size: 2vh;
    font-weight: 400;
    color: #1A1A51;
}

div#firstCell {
    display: none;
}

div#bloque3 #secondCell {
    display: none;
}

div#bloque3 #link {
    margin-top: 2.5vh;
}

div#bloque3 #linkCell {
    display: none;
}

div#bloque3 #link #full {
    font-size: 2.5vh;
}

div#bloque3 #link a {
    color: #257AF4;
}

div#bloque3 #link img {
    margin-left: .5vh;
    margin-top: .8vh;
}

div#bloque4 {
    padding-top: 5vh;
}

div#bloque4 div#text1Bloq4 {
    font-size: 3.5vh;
}

div#bloque4 div#text2Bloq4 {
    padding-top: 1.5vh;
    font-size: 2.5vh;
}

div#bloque4 div#text2Bloq4 a {
    color: #257AF4;
}



div#bloque4 :nth-child(2) {
    margin-top: .5vh;
    margin-left: .5vh;
}

div#caro {
    padding-top: 3vh;
    width: auto;
    height: auto;
}

div#obj {
    margin-left: auto;
    margin-right: auto;
}

div#text2Bloq4IndexCell-2 {
    display: none;
}


#arrowmore {
    display: none;
}

.vermovil{
    display: none !important;
}

/*
@media only screen and (width >=1025px) and (width <=1400px) {

    /*Styles imgs*/

/*End styles imgs*/

/* div#bloque4 div#text2Bloq4 {
        font-size: 3vh;
    }

    div#bloque3 :first-child :nth-child(2) {
        font-size: 2.5vh;
    }
}
*/

@media screen and (max-width:767px) and (width <=1200px)  {

    div#firstCell {
        font-size: 4vh;
        color: #1A1A51;
        display: block;
        width: 100%;
        text-align: center;
        padding-top: 15vh;
    }


    div#bloque3 #secondCell {
        margin-top: 2vh;
        font-size: 3.5vh;
        font-weight: 400;
        color: #1A1A51;
        display: grid;
    }

    div#bloque3 :first-child #first {
        display: none;
    }

    div#bloque3 :first-child #second {
        display: none;
    }

    div#bloque3 #linkCell {
        margin-top: 2.5vh;
    }

    div#bloque3 #link {
        display: none;
    }

    div#text2Bloq4IndexCell-2 {
        margin-right: 4vh;
        font-size: 2.5vh;
        font-weight: 500;
        display: flex;
        padding-top: 15vh;
        color: #257AF4;
    }

    #arrowmore {
        margin-top: 1vh;
    }

    .vermovil{
        display: flex !important;
    }

}


@media screen and (min-width:768px) and (width <=1200px)  {

    div#firstCell {
        font-size: 4vh;
        color: #1A1A51;
        display: block;
        width: 100%;
        text-align: center;
        padding-top: 15vh;
    }


    div#bloque3 #secondCell {
        margin-top: 2vh;
        font-size: 3.5vh;
        font-weight: 400;
        color: #1A1A51;
        display: grid;
    }

    div#bloque3 :first-child #first {
        display: none;
    }

    div#bloque3 :first-child #second {
        display: none;
    }

    div#bloque3 #linkCell {
        margin-top: 2.5vh;
    }

    div#bloque3 #link {
        display: none;
    }

    div#text2Bloq4IndexCell-2 {
        margin-right: 4vh;
        font-size: 2.5vh;
        font-weight: 500;
        display: flex;
        padding-top: 15vh;
        color: #257AF4;
    }

    #arrowmore {
        margin-top: 1vh;
    }

    .vermovil{
        display: flex !important;
    }

}