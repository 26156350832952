img#fundacion {
    display: flex;
    width: 100%;
}

.moviltexto {
    display: none !important;
}

img#libertad {
    width: 140px;
    height: 83px;
    padding-left: 2vh;
}

div#bloq1Fun {
    padding-top: 5vh;
    margin-left: auto;
    margin-right: auto;
}

div#bloq1Fun :nth-child(2) {
    /*margin: 0 auto;*/
    margin-top: auto;
    margin-bottom: auto;
    font-size: 2vh;
    text-align: left;
}

div#bloq1Fun :nth-child(3) :first-child {
    margin: 0 auto;
    margin-top: 1vh;
    margin-bottom: auto;
    font-size: 4vh;
}

div#bloqImgs {
    padding-top: 5vh;
    margin-left: auto;
    margin-right: auto;
}

div#bloqImgs :nth-child(2) :nth-child(2) {
    padding-top: 4.5vh;
}

div#fun {
    font-size: 2vh;
    color: black;
    font-weight: 450;
}

div#linkFun {
    padding-top: 3vh;
}

div#linkFun :nth-child(2) {
    padding-left: 2vh;
}

div#bloq1Fun1 {
    padding-top: 5vh;
    padding-left: 0vh;
    display: none !important;

}

.slider {
    display: none !important;
}

/*@media only screen and (max-height: 936px) {


    img#fundacion {
        display: block;
        margin: auto;
        width: 90%;
    }*/

@media screen and (max-width:768px) and (width <=1200px) {

    #txt1 {
        padding: 4vh;
        padding-top: 0vh;
    }

    div#bloq1Fun1 {
        padding-top: 5vh;
        padding-left: 0vh;
        display: flex !important;

    }

    .slider {
        display: block !important;

    }

    img#fundacion {
        display: none;
        margin: auto;
        width: 90%;

    }

    div#linkFun1 {
        padding-top: 2vh;
        display: none;
    }


    div#bloqImgs1 {
        padding-top: 5vh;
        margin-left: auto;
        margin-right: auto;
        display: none;
    }

    div#bloqImgs {
        padding-top: 5vh;
        margin-left: auto;
        margin-right: auto;
        display: none;
    }

    div#bloq1Fun {
        padding-top: 5vh;
        margin-left: auto;
        margin-right: auto;
        display: none;
    }

    img#gopage {
        padding-left: 5vh;
    }


    img#arrow {
        height: 3vh;
        padding-bottom: 3vh;
    }

    img#libertad1 {
        width: 140px;
        height: 83px;
        padding-left: 2vh;
    }

    div#fun1 {
        font-size: 2.5vh;
        color: black;
        font-weight: 400;
    }


    .moviltexto {
        display: flex !important;
    }

    button[class="slick-arrow slick-next"] {
        display: none !important;
    }

}

@media screen and (min-width:768px) and (width <=1200px) {

    #txt1 {
        padding: 4vh;
        padding-top: 0vh;
    }

    div#bloq1Fun1 {
        padding-top: 5vh;
        padding-left: 0vh;
        display: flex !important;

    }

    .slider {
        display: block !important;

    }

    img#fundacion {
        display: none;
        margin: auto;
        width: 90%;

    }

    div#linkFun {
        padding-top: 30px;
    }


    div#linkFun1 {
        padding-top: 2vh;
        display: none;
    }


    div#bloqImgs1 {
        padding-top: 5vh;
        margin-left: auto;
        margin-right: auto;
        display: none;
    }

    div#bloqImgs {
        padding-top: 5vh;
        margin-left: auto;
        margin-right: auto;
        display: none;
    }

    div#bloq1Fun {
        padding-top: 5vh;
        margin-left: auto;
        margin-right: auto;
        display: none;
    }

    img#gopage {
        padding-left: 5vh;
    }


    img#arrow {
        height: 3vh;
        padding-bottom: 3vh;
    }

    img#libertad {
        width: 200px;
        height: 120px;
        padding-left: 200px;
    }

    div#fun1 {
        font-size: 2.5vh;
        color: black;
        font-weight: 400;
    }


    .moviltexto {
        display: flex !important;
    }

    button[class="slick-arrow slick-next"] {
        display: none !important;
    }
}