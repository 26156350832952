.pad {
    padding: 5vh;
    display: grid;

}

#pop {
    z-index: 1;
}

.pad2 {
    display: none !important;
}

img#logo {
    width: auto;
    height: auto;
}

div#navL {
    font-weight: 600;
    font-size: 2.5vh;
}

div#navL a {
    color: #1A1A51;
}

.pad-letters div#linkNav {
    padding: 2vh;
}

.pad-letters div#quienesomos {
    padding: 2vh;
}

.pad-letters Button {
    padding-left: 2vh;
    padding-right: 2vh;
    border-radius: 60vh;
    background-color: #141A51;
    height: 4vh;
    top: 20%;
    text-transform: none !important;
    font-size: 2vh;
}

div#menu {
    display: none;
}

div#cont {
    display: none;
}

@media screen and (max-width:767px) and (width <=1200px) {

    /*pantallas hasta 767px pantallas pequeñas*/

    img#logo {
        width: 150px;
        height: auto;
    }

    img#ham {
        width: 20%;
        height: auto
    }

    .pad {
        display: none;
    }

    div#hamMen {
        margin: auto;
        margin-right: 1vh !important;
    }

    .pad2 {
        padding: 3vh;
        padding-bottom: 2px;
        display: flex !important;
        background: white !important;
        width: 100% !important;
    }
}

@media screen and (min-width:768px) and (width <=1200px) {

    /*pantallas desde 768px pantallas grandes*/

    img#logo {
        width: 32%;
        height: auto;
    }

    img#ham {
        width: auto;
        height: 50%;
    }

    .pad {
        display: none;
    }

    div#hamMen {
        margin: auto;
        margin-left: 200px;
    }

    .pad2 {
        padding: 5vh;
        display: flex !important;
        background: white !important;
        height: 10%;
    }
}

@media screen and (min-width:900px) and (max-width:1200px){

    img#logo {
        width: 15%;
        height: auto;
    }

}