div#cont1 {
    z-index: 0;

}

.video-wrapper {
    /* Telling our absolute positioned video to 
    be relative to this element */
    position: absolute;
    width: 100%;
    height: 55%;
    left: 0;
    /* Will not allow the video to overflow the 
    container */
    overflow: hidden;
    /* Centering the container's content vertically 
    and horizontally */
    text-align: center;
    display: block;
    align-items: center;
    justify-content: center;
}

img#industry {
    width: 100%;
    height: auto;
}

div#divCell {
    display: none;
}

div#divCell-2 {
    display: none;

}

div#text1Index {
    font-size: 5vh;
    color: yellow;
    /*color: #F7BA39;*/
    font-weight: 500;
}

div#opcionesIndex {
    padding-top: 4vh;
}

div#opcionesIndex a {
    font-size: 2.8vh;
    color: #F7BA39;
    font-weight: 500;
}

div#imgDivIndex {
    margin-top: 25%;
    margin-bottom: auto;
}

img#divV {
    height: 160px;
}

div#opcionesIndex {
    margin-top: auto;
    margin-bottom: auto;
}

div#bloque4Index {
    padding-top: 5vh;
    padding-bottom: 5vh;
}

div#bloque2+div#bloque4Index {
    padding-top: 8vh;
    padding-bottom: 5vh;
}

div#text2Bloq4Index :nth-child(2) {
    margin-top: .5vh;
    margin-left: .5vh;
}

div#bloque4Index div#title1Bloq4Index {
    font-size: 60px;
    color: #1A1A51;
    padding-bottom: 2vh;

}

div#bloque4Index div#text1Bloq4Index {
    font-size: 4vh;
    color: #1A1A51;
}

div#bloque4Index div#text1Bloq4IndexCell {
    display: none;
}

div#bloque4Index div#text2Bloq4Index {
    padding-top: 2vh;
    justify-content: right;
    font-size: 2.5vh;
    font-weight: 500;
}

div#bloque4Index div#text2Bloq4IndexCell {
    display: none !important;
}


div#bloque4Index div#text2Bloq4Index a {
    color: #257AF4;
}

div#bloque4Index div#text2Bloq4IndexCell a {
    color: #257AF4;
}

Button#contacto {
    padding-left: 2vh;
    padding-right: 2vh;
    border-radius: 60vh;
    height: 4vh;
    width: 50%;
    text-transform: none !important;
    font-size: 2vh;
}

div#caro2 {
    margin-left: auto;
    margin-right: auto;
}

.verslider {
    display: none !important;
}

.verslider2 {
    margin: auto !important;
    width: 100%;
}



div#text2Bloq4IndexCell-0 {
    display: none;
}


@media screen and (width <=768px) {
    /*
    img#industry {
        width: 100%;
    }

    div#bloque4Index div#text2Bloq4Index {
        font-size: 3vh;
    }

    .video-wrapper {
        height: 62%;
    }

    div#opcionesIndex a {
        font-size: 3.2vh;
    }

    */

}


@media screen and (max-width:767px) and (width <=1200px) {

    /*pantallas hasta 767px pantallas pequeñas*/

    #flecha{

        width: auto;
        height: 27px;
        
    }

    div#img1In {
        display: none;
    }

    #textcardmain {
        font-size: 2vh;

    }

    div#imgDivIndex {
        margin-top: 6%;
        margin-bottom: auto;
    }

    div#text1Index {
        font-size: 3vh;
    }

    div#opcionesIndex {
        font-size: 2.5vh;
        margin-top: 2%;
        padding-top: 10px;
    }

    div#bloque2 {
        text-align: center;
    }

    div#bloque4Index div#text1Bloq4Index {
        display: none;
    }

    div#bloque4Index div#text1Bloq4IndexCell {
        padding-top: 14px;
        font-size: 3vh;
        font-weight: 500;
        color: #1A1A51;
        display: flex !important;
        text-align: center;
        
    }

    div#bloque4Index div#text2Bloq4Index {
        display: none;
    }

    div#textLink {
        text-align: center;
        color: #257AF4;
        
    }

    div#bloque4Index div#text2Bloq4IndexCell {
        padding-top: 12px;
        font-size: 2.5vh;
        font-weight: 500;
        display: flex !important;
        width: 30%;
       
    }

    div#bloque4Index div#text2Bloq4IndexCell-0 {
        padding-top: 14px;
        font-size: 2.5vh;
        font-weight: 500;
        display: flex !important;
        width: 30%;
        padding-left: 10px;
    }

    div#bloque4Index div#text2Bloq4IndexCell-1 {
        display: flex !important;
        font-size: 2.5vh;
    }


    div#bloque4Index div#text2Bloq4IndexCell a {
        font-size: 2vh;
    }

    img#division[alt='cell'] {
        height: 3px;
        width: 100%;
    }

    div#divCell {
        display: block;
    }

    div#divCell-2 {
        display: block;
        margin-bottom: 20px;
    }

    #objIn {
        display: block;
    }

    div#sliderzz .slick-list {
        width: 85vw;
    }

    div#text1Index {
        font-size: 5vh;
        color: #1A1A51;
        font-weight: 500;
    }




    div#opcionesIndex a {
        font-size: 2.8vh;
        color: #1A1A51;
        font-weight: 500;
    }

    .verslider {
        display: block !important;
    }

    .verslider2 {
        display: none !important;
    }

    div#bloque4Index div#title1Bloq4Index {
        font-size: 60px;
        color: #1A1A51;
        padding-bottom: 2vh;
        display: none;
    }

    .video-wrapper {
        display: none;
    }

    button[class="slick-arrow slick-next"] {
        display: none !important;
    }


    button[class="slick-arrow slick-prev"] {
        display: none !important;
    }

}

@media screen and (min-width:768px) and (width <=1200px) {

    /*pantallas desde 768px pantallas grandes*/


    div#img1In {
        display: none;
    }

    #textcardmain {
        font-size: 2vh;

    }

    div#imgDivIndex {
        margin-top: 10%;
        margin-bottom: auto;
    }

    div#text1Index {
        font-size: 3vh;
    }

    div#opcionesIndex {
        font-size: 2.5vh;
    }

    div#bloque2 {
        text-align: center;
    }

    div#bloque4Index div#text1Bloq4Index {
        display: none;
    }

    div#bloque4Index div#text1Bloq4IndexCell {
        padding-top: 14px;
        font-size: 3vh;
        font-weight: 500;
        color: #1A1A51;
        display: flex !important;
        text-align: center;
        padding-left: 15px;
    }

    div#bloque4Index div#text2Bloq4Index {
        display: none;
    }

    div#textLink {
        padding-right: 5px;
        text-align: center;
        color: #257AF4;
    }

    div#bloque4Index div#text2Bloq4IndexCell {
        padding-top: 12px;
        font-size: 2.5vh;
        font-weight: 500;
        display: flex !important;
        width: 30%;
       
    }

    div#bloque4Index div#text2Bloq4IndexCell-0 {
        padding-top: 14px;
        font-size: 2.5vh;
        font-weight: 500;
        display: flex !important;
        width: 30%;
        padding-left: 70px;
    }

    div#bloque4Index div#text2Bloq4IndexCell-1 {
        display: flex !important;
        font-size: 2.5vh;
    }


    div#bloque4Index div#text2Bloq4IndexCell a {
        font-size: 2vh;
    }

    img#division[alt='cell'] {
        height: 3px;
        width: 100%;
    }

    div#divCell {
        display: block;
    }

    div#divCell-2 {
        display: block;
        margin-bottom: 20px;
    }

    #objIn {
        display: block;
    }

    div#sliderzz .slick-list {
        width: 95vw;
    }

    div#text1Index {
        font-size: 5vh;
        color: #1A1A51;
        font-weight: 500;
    }

    div#opcionesIndex {
        padding-top: 4vh;
    }

    div#opcionesIndex a {
        font-size: 2.8vh;
        color: #1A1A51;
        font-weight: 500;
    }

    .verslider {
        display: block !important;
    }

    .verslider2 {
        display: none !important;
    }

    div#bloque4Index div#title1Bloq4Index {
        font-size: 60px;
        color: #1A1A51;
        padding-bottom: 2vh;
        display: none;
    }

    .video-wrapper {
        display: none;
    }

    button[class="slick-arrow slick-next"] {
        display: none !important;
    }


    button[class="slick-arrow slick-prev"] {
        display: none !important;
    }

}

@media screen and (max-width:480px) {

    div#sliderzz .slick-list {
        width: 95vw;
    }

}

@media screen and (min-width:900px) and (max-width:1200px) {

    div#text1Index {
        font-size: 10vh;
        color: #1A1A51;
        font-weight: 500;
        margin-top: 6vh;
        position: initial;
    }


}