img#electronic {
    display: block;
    width: 100%;
}


.video-wrapperQH {
    /* Telling our absolute positioned video to 
    be relative to this element */

    position: absolute;
    width: 100%;
    height: 65%;
    left: 0;
    /* Will not allow the video to overflow the 
    container */
    overflow: hidden;
    /* Centering the container's content vertically 
    and horizontally */
    text-align: center;
    display: block;
    align-items: center;
    justify-content: center;

}

/*
Tal parece es un condiconal de opciones
div#textElec[direction='row'] {
    padding-top: 2vh;
}*/

div#textElec {
    padding-top: 70vh;
}

div#text1Elec {
    font-size: 4vh;
}

div#text2Elec {
    font-size: 2.5vh;
}

div#div img {
    padding-top: 6vh;
    padding-bottom: 8vh;
}

div#bloq2Elec {
    padding-top: 5vh;
}

div#bloq2Elec :first-child {
    font-size: 3vh;
}

div#bloq2Elec :nth-child(2) {
    padding-top: 1.8vh;
    padding-bottom: 1vh;
    font-size: 2vh;
}

div#bloq3Elec {
    padding-bottom: 4vh;
    font-size: 4.2vh;
    padding-top: 0vh;
}

div#C2Space {
    padding-top: 4vh;
}


div#bloq2Elec div#Title {
    font-size: 4.2vh;
}

div#bloq2Elec div#text {
    font-size: 2vh;
}

#textPre {
    padding: 3vh;
    padding-top: 0vh;
    margin-left: 2vh;

}

#textPre-sub {
    border: 2px solid #141a51;
    padding: 1vh;
}

#textPre2 {
    padding: 3vh;
    padding-left: 0vh;
    padding-top: 0vh;
    border: 2px solid #141a51;
    padding: 1vh;
    margin-bottom: 3vh;
    margin-right:3vh;
}

img#imgPre {
    width: 3.2cm;
    height: 4.39cm;

}

img#imgPre2 {
    width: 400px;
    height: 300px;
    
}

div#container2 {
    padding-top: 2vh;

}

@media screen and (max-width:767px) and (width <=1200px)  {

    .video-wrapperQH {
        /* Telling our absolute positioned video to 
        be relative to this element */
    
        position: absolute;
        width: 100%;
        height: 40%;
        left: 0;
        /* Will not allow the video to overflow the 
        container */
        overflow: hidden;
        /* Centering the container's content vertically 
        and horizontally */
        text-align: center;
        display: block;
        align-items: center;
        justify-content: center;
    
    }

    div#textElec {
        padding-top: 45vh;
    }

    #espacards{
        padding-bottom: 20px;
    }
    div#bloq2Elec div#text{
        font-size: 3vh;
    }

    div#bloq2Elec :nth-child(2) {
        font-size: 3vh;

    }

    div#bloq2Elec {
        padding-top: 0vh;
        
    }
    div#bloq2Elec :first-child {
        font-size: 3vh;
        margin-bottom: 20px;
    }

}

@media screen and (min-width:768px) and (width <= 1200px)  {

    .video-wrapperQH {
        /* Telling our absolute positioned video to 
        be relative to this element */
    
        position: absolute;
        width: 100%;
        height: 65%;
        left: 0;
        /* Will not allow the video to overflow the 
        container */
        overflow: hidden;
        /* Centering the container's content vertically 
        and horizontally */
        text-align: center;
        display: block;
        align-items: center;
        justify-content: center;
    
    }

    div#bloq2Elec div#text{
        font-size: 3vh;
    }

    div#textElec {
        padding-top: 45vh;
    }

    #espacards{
        padding-bottom: 20px;
    }

    div#bloq2Elec :nth-child(2) {
        font-size: 3vh;

    }
    div#bloq2Elec :first-child {
        font-size: 3vh;
        margin-bottom: 20px;
    }
}