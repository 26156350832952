div#divcity {
    padding-top: 3vh;
}


img#division[alt='divH'] {
    width: 100%;
    height: auto;
}

img[src='images/iconoVacio.png'],
img[src='images/iconoRelleno.png'] {
    padding-right: 5px;
}


#imgcity {
    display: block;
    width: 100%;
    height: auto;
    align-content: center;
}

/*Init text styles*/

div#titleNet1 {
    font-size: 3.5vh;
    font-weight: 500;
}

div#bloque1Work {
    padding-top: 5vh;
}

div#bloq1 {
    margin-top: 0;
    margin-bottom: auto;
}

img#imgGas {
    width: 100%;
    height: auto;
    border: 2px solid #141a51;
}

img#imgGas2 {
    width: auto;
    height: auto;
    border: 2px solid #141a51;
}

img#imgGas3 {
    width: 100%;
    height: auto;
    margin-left: 2%;
    border: 2px solid #141a51;
}



div#titleRow2 {
    font-size: 4vh;
    font-weight: 500;
}

div#titleRow2_Gas {
    font-size: 4vh;
    font-weight: 500;
    padding-bottom: 50vh;
}

div#textRow2 {
    font-size: 2.8vh;
}


/*End text styles*/


/*Init styles row and colums*/


div#row1Text {
    padding-top: 2vh;
}



div#row2Text {
    padding-top: 5vh;
}



/*End styles row and colums*/


/*Init imgs styles*/

img#division[alt='divH'] {
    width: 100%;
    height: 3px;

}



img[src='images/iconoVacio.png'],
img[src='images/iconoRelleno.png'] {
    padding-right: 5px;
}


#imgcity {
    display: block;
    width: 100%;
    height: auto;
    align-content: center;
}

/*End imgs styles*/
