@media screen and (max-width:767px) and (width <=1200px){
    .padCell {
        padding: 3vh;
    }

    div#menu {
        display: block;
    }

    .col1Menu {
        color: white;
        font-size: 3vh;
        font-weight: 500;
    }

    .col2Menu {
        color: white;
        font-size: 2.8vh;
        font-weight: 300;
    }

    div#cont {
        display: grid;
        margin: 0 auto;
    }

    img#logoCell {
        width: auto;
        height: auto;
        max-height: 220px;
    }
}

@media screen and (min-width:767px) and (width <=1200px){
    .padCell {
        padding: 3vh;
    }

    div#menu {
        display: block;
    }

    .col1Menu {
        color: white;
        font-size: 3vh;
        font-weight: 500;
    }

    .col2Menu {
        color: white;
        font-size: 2.8vh;
        font-weight: 300;
    }

    div#cont {
        display: grid;
        margin: 0 auto;
    }

    img#logoCell {
        width: auto;
        height: auto;
        max-height: 220px;
    }
}