/*Init text styles*/

div#titleNet1 {
    font-size: 3.5vh;
    font-weight: 500;
}

div#subtitleNet1 {
    font-size: 2vh;
    font-weight: 300;
    margin-top: auto;
    margin-bottom: auto;
}

div#titleRow1 {
    font-size: 1.8vh;
    color: #b3b3b3;
}

div#subtitleRow1 {
    font-size: 2vh;
    font-weight: 500;
    color: #141a51;
}

div#textRow1 {
    font-size: 2vh;
}

#textRow1-t{
    font-size: 2.4vh;
    margin-bottom: 3vh;
    padding-right: 3vh;
    
}

#textRow1-t2{
    font-size: 2.4vh;
    margin-bottom: 3vh;
}


div#titleRow2 {
    font-size: 4vh;
    font-weight: 500;
}

div#textRow2 {
    font-size: 2.8vh;
}

div#titleRow3 {
    font-size: 4vh;
    font-weight: 500;
}

#titleRow3-t{
    font-size: 4vh;
    font-weight: 500;
}

#titleRow3-c{
    font-size: 4vh;
    font-weight: 500;
    height: 0.2vh ;
}

div#textRow3 {
    font-size: 3.5vh;
}

#textRow3-t{
    font-size: 3.5vh;
    margin-right: 5vh;
}

#textRow3-t2{
    font-size: 3.5vh;
    
}


div#textRow4 {
    font-size: 3.5vh;
}

/*End text styles*/


/*Init styles row and colums*/

div#divAntena {
    padding-top: 5vh;
}

div#row1Text {
    padding-top: 2vh;
}

div#subtitleRow1 {
    padding-top: 1vh;
}

div#row2Text {
    padding-top: 5vh;
}

#row2Text-t{
    padding-top: 4vh;
}

div#row3Text {
    padding-top: 5vh;
}

div#row4Text {
    padding-top: 6vh;
}

/*End styles row and colums*/


/*Init imgs styles*/

img#antena {
    display: block;
    width: 100%;
    height: auto;
}

/*End imgs styles*/



@media screen and (max-width:767px) and (width <=1200px){

    div#subtitleNet1 {
        display: none;
    }

    #textRow1-t{
        font-size: 2.4vh;
        margin-bottom: 3vh;
    }

    div#subtitleRow1 {
        font-size: 14px;
        font-weight: 500;
        color: #143451;
    }

    div#titleRow1 {
        font-size: 16px;
        color: #b3b3b3;
    }

    #titleRow3-c{
        font-size: 4vh;
        font-weight: 500;
        height: 0.2vh ;
        margin-bottom: 30px;
    }
    
    #textRow3-t{
        font-size: 3.5vh;
        margin-right: 5vh;
        margin-bottom: 20px;
    }
    
    div#textRow1 {
        font-size: 16px;
    }
    
}

@media screen and (min-width:768px) and (width <=1200px){

  
}