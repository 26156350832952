div#foot {
    padding-top: 5vh;
}

footer {
    background-color: #04264F;
    padding-top: 5vh;
    height: auto;
    width: 100%;
}

footer div {
    color: white;
}

footer #secondRow {
    align-content: center;
    justify-content: center;
    font-size: 2.2vh;
    width: 100%;
}

footer div div #first {
    font-weight: bold;
    padding-bottom: 2.5vh;
}

footer div div div {
    padding-bottom: 1vh;
}

div #inputText {
    color: #257AF4;
    font-size: 2vh;
}

div #inputCont {
    margin-top: 3vh;
}

div#email {
    width: 100%;
    /*height: 100px;*/
    align-items: center;
    justify-content: center;
    padding-top: 4vh;
}

div#input {
    width: 100%;
    padding-top: 4vh;
    /*height: 40px;*/
}

table#foot {
    width: 60%;
    border: 1px solid white;
    border-radius: 100px;
}


input[type="text"]#user {
    border: none;
    background-color: transparent;
    color: white;
}

#userCell {
    display: none;
}

table#foot td,
tr {
    height: 40px;
}

td#secondT {
    width: 80%;
    padding-left: 1vh;
}


td#firstT {
    width: 9%;
    font-size: 2.2vh;
    font-weight: 600;
    padding-left: 1vh;
}

td#tirthT {
    color: #257AF4;
    font-size: 3vh;
    font-weight: 600;
}

#label {
    font-size: 2.2vh;
}

td img {
    margin-top: 0.5vh;
    margin-bottom: auto;
}

footer div#lastRow {
    padding-top: 15vh;
    width: 100%;
    text-align: center;
}

/*tr,
td {
    border: 1px solid white;
}*/

@media only screen and (width >=1025px) and (width <=1400px) {
    footer {
        height: 65vh;
    }

    input[type="text"]#user {
        width: 110%;
    }

    #label {
        font-size: 2.8vh;
    }


    footer #secondRow {
        font-size: 2.8vh;
    }

    td#firstT {
        
        font-size: 2.8vh;
    }

    td#tirthT {
        font-size: 3.5vh;
    }

    td img {
        margin-top: 0.8vh;
    }
}

@media screen and (min-width:320px) and (max-width:1020px) {

    footer #secondRow {
        display: none !important;
    }

    footer {
        width: 100%;
    }

    td#send {
        display: none !important;
    }

    table#foot {
        width: 100%;
        table-layout: auto !important;
    }

    table#foot tr {
        height: 50%;
        width: 100%;
    }

    table#foot td {
        height: 50%;
    }

    #label {
        display: none;
    }

    td#firstT {
        width: 20%;
        padding-left: 1vh;
    }

    td#secondT {
        width: 100%;
        height: auto;
    }

    div#email {
        align-items: unset;
        justify-content: unset;
        padding-top: none;
    }

    div#input {
        width: auto;
        height: 50%;
    }

    footer div#lastRow {
        padding-top: 0;
        margin-top: auto;
        margin-bottom: auto;
        
    }

}

/*

<InputLabel sx={
                                            {
                                                color: 'rgba(255, 255, 255, 0.63)',
                                            }
                                        } id='label' >
                                            {word2}
                                        </InputLabel>
                                        <Input sx={{ color: 'white' }} id='user' autoComplete='off' disableUnderline />

*/