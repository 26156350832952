div#containerNot {
    padding-top: 8vh;
    flex-wrap: wrap;
}

#txtCardTitle{
    font-size: 22px;
    font-weight: 100;
}

#txtCardTitle-2{
    font-size: 19px;
    font-weight: 100;
}

#txtCard{
    font-size: 17px;
    font-weight: 500;
   
}

#txtCard-2{
    font-size: 17px;
    font-weight: 500;
   
}

img#division {
    width: 100%;
    height: auto;
}

#btnoptions {
    cursor: pointer;
}

@media screen and (max-width:767px) and (width <=1200px) {

    div#containerNot {
        padding-top: 8vh;
        
        flex-wrap: wrap;
    }

    #txtCardTitle{
        font-size: 17px;
        font-weight: 500;

    }

    #txtCardTitle-2{
        font-size: 13px;
        font-weight: 540;
    }

    #txtCard{
        font-size: 11.86px;
        font-weight: 500;

    }

    #txtCard-2{
        font-size: 13px;
        font-weight: 500;
        
    }

    #txtCardTitle-3{
        font-size: 14px;
        font-weight: 540;
    }

    #txtCard-3{
        font-size: 13.3px;
        font-weight: 500;

    }


    

}

@media screen and (min-width:768px) and (width <=1200px) {}