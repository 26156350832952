div#historia {
    color: #1A1A51;
    width: 90%;
    margin-right: auto;
    padding-bottom: 50px;
}

div#imgDivHis {
    margin-top: 35px;
    margin-bottom: 10px;
}

div#history {

    font-size: 4vh;
    font-weight: 500;
}

a#indexH {
    color: #1A1A51;
}

div#opcionesHis {
    padding-top: 35px;
}

.opcionesHis {
    font-size: 2.8vh;
    color: #1A1A51;
    font-weight: 500;
}

.cambioColor {
    color: #F7BA39;
    font-size: 2.8vh;
    font-weight: 500;
}

div#containerHis {
    flex-wrap: wrap;
    padding-top: 3vh;
}

div#opciones li {
    margin-left: 6vh;
    font-size: 2vh;
    font-weight: 500;
}

ul#listCard {
    padding-inline-start: 10px;
    list-style-type: square;
}

.portCardCl {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.portBodyCl {
    flex: 1 0 auto;
    justify-content: center;
    flex-direction: column;
}

.portButCl {
    display: flex;
    justify-content: flex-start;
}

img#division[alt='divH'] {
    width: 200px;
    height: auto;
}


img[src='images/iconoVacio.png'],
img[src='images/iconoRelleno.png'] {
    padding-right: 5px;
}

.espa {
    margin-bottom: 22vh;
}

.espa2 {
    margin-bottom: 13vh;
}

#titulosHisto {
    color: #1A1A51;
    width: 90%;

    margin-right: auto;
    padding-top: 13px;
    font-size: 25px;

}

div#subcontainerHisto {
    padding-top: 25px;
    flex-wrap: wrap;
}

#filtroH-icon {
    display: none;

}

/*
@media only screen and (width >=1025px) and (width <=1400px) {

    div#historia {
        color: #1A1A51;
        padding-bottom: 5vh;
    }

    div#containerHis {
        padding-left: 0vh;
        padding-top: 3vh;
        
    }

    div#opcionesHis {
        padding-top: 4vh;
    }
}
*/


@media screen and (max-width:767px) and (width <=1200px) {

    #cardminhistori {
        display: flex;
    }

    #cardminhistoria {
        display: none;
    }

    div#containerHis {
        display: block;

    }

    #filtroH-icon {
        display: flex;

    }

    div#imgDivHis {
        display: none;
    }

    div#opcionesHis {
        display: none;
    }

    .espa {
        width: 100%;
        margin-bottom: 2vh;
    }

    .espa2 {
        width: 100%;
        margin-bottom: 2vh;
    }

    .portButCl {
        display: flex;
        justify-content: flex-start;
        padding: 10vh;
    }

    #cen {
        align-items: center;
    }

    img#division[alt='divH'] {
        width: 250px;
        height: auto;
    }

    .css-gznlmf-MuiGrid-root>.MuiGrid-item {
        max-width: none;
        width: 240px;
    }

    div#titulosHisto {
        display: none;
    }
}

@media screen and (min-width:768px) and (width <=1200px) {

    #cardminhistori {
        display: flex;
    }

    #cardminhistoria {
        display: none;
    }

    div#opcionesHis {
        display: none;
    }

    div#containerHis {
        padding-top: 10vh;
        display: block;
    }

    div#imgDivHis {
        display: none;
    }

    #filtroH-icon {
        display: flex;
    }

    .espa {
        width: 100%;
        margin-bottom: 2vh;
    }

    .espa2 {
        width: 100%;
        margin-bottom: 2vh;
    }

    .portButCl {
        display: flex;
        justify-content: flex-start;
        padding: 10vh;
    }

    #cen {
        align-items: center;
    }

    img#division[alt='divH'] {
        width: 250px;
        height: auto;
    }

    .css-gznlmf-MuiGrid-root>.MuiGrid-item {
        max-width: none;
        width: 240px;
    }

    div#titulosHisto {
        display: none;
    }
}