.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  text-decoration: none;
}

div#todo {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media only screen and (width >=768px) and (max-width:1400px) {

  div#todo {
    width: 95%;
  }

}

@media only screen and (width >=280px) and (width <=480px) {

  div#todo {
    width: 95%;
  }

  .navbar {
    background: white !important;
  
  }

  .nav.scroll {
    background: blue !important;
}

}

@media screen and (min-width:900px) and (max-width:1200px){

  div#todo {
    width: 95%;
  }
 #root{
  width: auto;
}


}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}