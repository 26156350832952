div#firstCardCS a {
    float: right;
}

.modal{
    width: 800px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 ; 
    align-self: center;
}

#pdf{
    width: 99%;
    height: 100%;
    text-align: center;
    display: flex;
    margin: 0; 
    align-self: center;
}

.swal2-popup {
    width: auto;
    
}