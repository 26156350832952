/*Init styles text*/
div#contact {
    font-size: 4vh;
    font-weight: 500;
    color: #1A1A51;
}

div#placeContact {
    font-size: 4vh;
    color: #1A1A51;
    font-weight: 500;
}

div#textContact {
    font-size: 2vh;
    font-weight: 400;
    display: none;
}

div#sendCon {
    padding-top: 5vh;
}

button#sendCon {
    font-size: 3vh;
    float: right;
    border-radius: 60vh;
    text-transform: none !important;
}

div#emailContact {
    font-size: 2.5vh;
    font-weight: 500;
}

div#numberContact {
    font-size: 2.5vh;
    font-weight: 500;
}

div#titleContac {
    font-size: 4vh;
    font-weight: 500;
    color: #1A1A51;
    padding-top: 5vh;
    padding-left: 16vh;
}

/*End styles text*/


div#imgCali {
    justify-items: center;
    align-items: center;
}

/*Init styles img´s*/
img#cali {
    width: 100%;
    height: auto;
}

img#peruCity {
    width: 100%;
    height: auto;
}

img#division {
    width: 100%;
    height: auto;
}

/*End styles img´s*/

div#bloque3Contact {
    padding-top: 15vh;
}

div#bloque3ContactForm {
    padding-top: 5vh;
    display: grid;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

table#contact {
    width: 100%;
    display: table;
    border: 1px solid #141A51;
    border-radius: 100px;
}

table#contactCell {
    display: none;
}

div#bloque3ContactFormCell {
    display: none;
}

/*Quitar flechas de input number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media only screen and (max-height: 936px) {

    /*Init text styles*/

    div#textContact {
        font-size: 2.5vh;
    }

    /*End text styles*/

}

@media screen and (width >=280px) and (width <=480px) {

    div#titleContac {
        padding-left: 8%;
    }

    table#contact {
        display: none;
    }

    table#contactCell {
        display: table;
        width: 100%;
        border-spacing: 0 10px;
    }

    table#contactCell td {
        border: 1px solid #141A51;
        border-radius: 100px;
    }

    div#bloque3ContactForm {
        display: none;
    }

    div#bloque3ContactFormCell {
        padding-top: 5vh;
        display: grid;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    #textFormCell {
        color: #141A51;
        font-weight: 600;
        padding-left: 5%;
    }

}